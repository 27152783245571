import * as React from "react";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme } from "@mui/material/styles";
import SignUpNavBar from "../Navbar/SignUpNavBar";
import { useAuth } from "../contexts/AuthContext";
import Alert from "@mui/material/Alert";
import { useNavigate } from "react-router-dom/dist";
import { Button, Checkbox, Form, Input, message, Select } from "antd";
import "./Signup.css";
import { PhoneOutlined } from "@mui/icons-material";
import { getFunctions, httpsCallable } from "firebase/functions";
import ReCAPTCHA from "react-google-recaptcha";
import GoogleSignInButton from "../Media/GoogleSignInButton";
import { auth } from "../Firebase/firebase";
const theme = createTheme();

export default function SignUp() {
  const { signup } = useAuth();
  const [errorMessage, setErrorMessage] = React.useState("");
  const { currentUser, logout } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [isFetched, setIsFetched] = React.useState(false);
  const countryCodes = [
    { code: "+1", name: "US" },
    { code: "+44", name: "UK" },
    { code: "+91", name: "India" },
    // Add more country codes as needed
  ];
  // Google Recaptcha
  const [recaptcha, setRecaptcha] = React.useState(null);
  const recaptchaRef = React.useRef(null);
  const getFunctionsInstance = getFunctions();
  const navigate = useNavigate();
  const verifyRecaptcha = httpsCallable(
    getFunctionsInstance,
    "verifyRecaptcha"
  );
  const onRecaptchaChange = (value) => {
    setRecaptcha(value);
  };

  async function onFinish(values) {
    try {
      setLoading(true);
      await verifyRecaptcha({ recaptchaToken: recaptcha });
      await signup(
        values.email,
        values.password,
        values.firstName,
        values.lastName,
        values.username,
        values.newsLetter
      );

      message.success("Account created successfully");

      navigate("/email-verification", { replace: true });
    } catch (error) {
      setLoading(false);

      // Ensure that the error message is a string before displaying it
      const errorMessage = error.message || "Error while creating account.";
      message.error("Failed to create account: " + errorMessage);

      setErrorMessage(errorMessage);
      recaptchaRef.current.reset();
      setTimeout(() => {
        setLoading(false);
        setErrorMessage("");
      }, 5000);

      navigate("?=account_creation_failed", { replace: true });
    }
  }

  React.useEffect(() => {
    if (currentUser !== undefined) {
      setIsFetched(true);
    }
  }, [currentUser]);

  const onFinishFailed = (errorInfo) => {
    setLoading(true);
    message.error("Failed to create account");
    recaptchaRef.current.reset();
    setLoading(false);
  };

  return (
    <>
      <SignUpNavBar />
      <div className="sign-up-container">
        <div className="sign-up-form">
          {errorMessage && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {errorMessage}
            </Alert>
          )}
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <h1>Join the community </h1>
          <small>
            Discover the most delicious gastro-friendly recipes on the web by
            becoming a part of our vibrant community today!
          </small>
          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: false,
              newsLetter: false,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <GoogleSignInButton />

            <Form.Item
              label="First Name"
              name="firstName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your username!",
                },
                {
                  min: 5,
                  message: "Username must be at least 5 characters long",
                },
                {
                  pattern: /^[a-zA-Z0-9_]+$/,
                  message:
                    "Username can only contain letters, numbers, and underscores",
                },
              ]}
            >
              <Input placeholder="Enter your Username" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,

                  message: "Please input your Email!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  type: "password",
                  message: "Please input your password!",
                },
                {
                  min: 6,
                  message: "Password must be at least 6 characters long",
                },
                {
                  pattern: new RegExp('(.*[!@#$%^&*()_+{}:"<>?].*)'),
                  message:
                    "Password must contain at least one special character!",
                },
                {
                  pattern: new RegExp("(.*[A-Z].*)"),
                  message:
                    "Password must contain at least one uppercase letter!",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>
            <Form.Item name="newsLetter" valuePropName="checked">
              <Checkbox>
                Would you like to opt-in to our newsletter and receive updates
                and promotions?
              </Checkbox>
            </Form.Item>
            <div>
              <Form.Item>
                <Button
                  type="dashed"
                  htmlType="submit"
                  className="submit-button"
                  disabled={loading}
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
            {/* {ui.start("#firebaseui-auth-container", {
              signInOptions: [
                //List of OAuth providers supported.
                firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              ],
              signInFlow: "popup",
              callbacks: {
                signInSuccessWithAuthResult: () => false,
              },
            })} */}
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_RECAPTCHA}
              onChange={onRecaptchaChange}
            />
          </Form>
        </div>
      </div>
    </>
  );
}
