import React, { useEffect, useRef, useState } from "react";
import { auth, db } from "../Firebase/firebase";
import {
  GoogleAuthProvider,
  sendEmailVerification,
  signInWithPopup,
} from "firebase/auth";
import { Button, Modal, Input, Tooltip, Form, message, Checkbox } from "antd";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "../contexts/AuthContext";
import { InfoOutlined } from "@material-ui/icons";
import { Google, Person2Outlined } from "@mui/icons-material";

// This is also used for creating a new user Account
const GoogleSignInButton = () => {
  const provider = new GoogleAuthProvider();
  const { signup } = useAuth();
  const [userInformationModal, setUserInformationModal] = useState(false);
  const formRef = useRef(null);
  async function activateGoogleSignIn() {
    //   signInWithPopup(auth, provider)
    //     .then(async (result) => {
    //       // The signed-in user info.
    //       const user = result.user;
    //       // Check if the user already exists in Firestore
    //       const userRef = doc(db, 'users', user.uid);
    //       const userDoc = await getDoc(userRef);
    //       if (!userDoc.exists()) {
    //         // If the user does not exist in Firestore, call your signup function
    //         const newsletterSubscription = true;  // Please update this with your logic
    //         await signup(
    //           user.email,
    //           newsletterSubscription
    //         );
    //         // This is an example to save user document in Firestore, please modify it according to your need
    //         await setDoc(doc(db, 'users', user.uid), {
    //           firstName: user.displayName,  // It may require parsing to get first and last names
    //           lastName: user.displayName,
    //           email: user.email,
    //           username: user.displayName,  // You may need to create a unique username
    //           newsletterSubscription: newsletterSubscription,
    //           // more fields
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       // Handle Errors here.
    //       const errorCode = error.code;
    //       const errorMessage = error.message;
    //       console.error(`Error in signInWithPopup: ${errorCode} ${errorMessage}`);
    //     });
  }

  // Handle sign-in failures
  const onFinishFailed = (errorInfo) => {
    message.error("Failed to create account");
  };
  const checkUsernameAvailablity = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    try {
      const docSnap = await getDocs(q);
      if (docSnap.size > 0) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      message.error("Failed to check username availability. Try again");
      return false;
    }
  };

  async function sendConfirmationEmail(user) {
    try {
      await sendEmailVerification(user);
      return Promise.resolve();
    } catch (error) {
      message.error("Something went wrong. Try again");
      return Promise.reject();
    }
  }
  // Handle sign-in wins
  const onFinish = (values) => {
    if (checkUsernameAvailablity(values.username)) {
      signInWithPopup(auth, provider)
        .then(async (result) => {
          // This code can be used later for accessing Google APIs on behalf of the user or linking accounts
          // const googleUser = result.user;
          // const idToken = googleUser.getIdToken();
          // const credential = auth.GoogleAuthProvider.credential(idToken);
          // const token = (result.credential && 'accessToken' in result.credential) ? result.credential.accessToken : null;

          // The signed-in user info.
          const user = result.user;
          // Check if the user already exists in Firestore
          const userRef = doc(db, "users", user.uid);
          const userDoc = await getDoc(userRef);
          if (!userDoc.exists()) {
            values.firstname =
              values.firstname === undefined ? "" : values.firstname;
            values.lastname =
              values.lastname === undefined ? "" : values.lastname;
            // User sign-up logic
            await sendConfirmationEmail(user);
            await setDoc(doc(db, "users", user.uid), {
              firstname: values.firstname,
              lastname: values.lastname,
              uid: user.uid,
              gastroTag: "No Gastro Tag",
              email: user.email,
              username: values.username,
              newsletter:
                values.newsletter === undefined ? false : values.newsletter,
              privateProfile: false,
              monetization: false,
              allowComments: true,
              dailyAmountOfPostsCount: 0,
              dailyAmountOfTimesCanChangeSettings: 0,
              passwordChangeAttempts: 0,
              dailyAmountOfTimesBugReport: 0,
            });
          } else {
            message.info("User already exists. Signing in");
          }
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          // Error in signInWithPopup: auth/cancelled-popup-request Firebase: Error (auth/cancelled-popup-request).
          if (errorCode !== "auth/cancelled-popup-request") {
            message.error(
              `Error in signInWithPopup: ${errorCode} ${errorMessage}`
            );
          }
        });
    } else {
      message.error("Username is already taken. Try another one");
    }
  };

  return (
    <>
      <Button
        className="
      flex items-center justify-center gap-2 
      bg-white border border-gray-300 rounded-md 
      hover:bg-gray-50 text-gray-700 font-medium 
      px-4 py-2 shadow-sm transition-all duration-150
    "
        onClick={() => setUserInformationModal(true)}
      >
        <Google className="w-5 h-5" />
        <span>Sign Up With Google.</span>
      </Button>
      <Form
        ref={formRef}
        name="basic_signup"
        initialValues={{
          remember: false,
          newsLetter: false,
        }}
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <Modal
          title="Sign Up With Google"
          centered
          open={userInformationModal}
          onOk={() => {
            formRef.current.submit();
          }}
          okButtonProps={{
            className:
              "bg-blue-500 hover:bg-blue-600 border-blue-500 hover:border-blue-600",
          }}
          onCancel={() => setUserInformationModal(false)}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input your username!",
              },
              {
                min: 5,
                message: "Username must be at least 5 characters long",
              },
              {
                pattern: /^[a-zA-Z0-9_]+$/,
                message:
                  "Username can only contain letters, numbers, and underscores",
              },
            ]}
          >
            <Input
              placeholder="Enter your username"
              prefix={
                <Person2Outlined className="site-google-item-icon-flow" />
              }
              suffix={
                <Tooltip title="Your username should be unique">
                  <InfoOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              }
            ></Input>
          </Form.Item>
          <Form.Item name="remember" valuePropName="checked">
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Form.Item name="newsLetter" valuePropName="checked">
            <Checkbox>
              Would you like to opt-in to our newsletter and receive updates and
              promotions?
            </Checkbox>
          </Form.Item>
        </Modal>
      </Form>
    </>
  );
};

export default GoogleSignInButton;
