import { CheckCircleOutline } from "@mui/icons-material";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { db } from "../Firebase/firebase";
import UserSignedIn from "../Navbar/UserSignedIn";
import "./Post.css";
import { useAuth } from "../contexts/AuthContext";
import Navbar from "../Navbar/Navbar";
import RateRecipeDialog from "./RateRecipeDialog";
import Bookmark from "../../widgets/Bookmarks";
import { Button, Result, message } from "antd";
import { InfoSharp, SaveAlt } from "@material-ui/icons";
import ReactPDF, { pdf } from "@react-pdf/renderer";
import PostDocument from "../../widgets/PdfPostCreator";
import ShareOnTwitter from "../../widgets/ShareOnTwitter";
import ShareOnFacebook from "../../widgets/ShareOnFacebook";
import ShareOnPinterest from "../../widgets/ShareOnPintrest";

// ---------------------------------------------------------- A single post page -------------------------------------------------
export default function Post() {
  const { recipe } = useParams();
  const { currentUser } = useAuth();
  const [postInformation, setPostInformation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isPrivateLock, setPrivateLock] = useState(false);
  const [allowComments, setAllowComments] = useState(false);
  const [url, setUrl] = useState(null);
  const [saveLoading, setSaveLoading] = useState(false);

  // Save Post information as pdf file
  const SaveButton = ({ postInformation }) => {
    const handleClick = async () => {
      setSaveLoading(true);
      if (postInformation && typeof postInformation.data === "function") {
        if (postInformation?.data()?.imageUrl) {
          // Replace this URL with your Firebase Cloud Function URL
          try {
            const pdfDoc = (
              <PostDocument
                title={postInformation?.data()?.title}
                description={postInformation?.data()?.desc}
                ingredients={postInformation?.data()?.ingredients}
                instructions={postInformation?.data()?.instructionsForRecipe}
                email={postInformation
                  ?.data()
                  ?.email.slice(
                    0,
                    postInformation.data().email.lastIndexOf("@")
                  )}
              />
            );

            const pdfInstance = await pdf(pdfDoc).toBlob();
            const fileURL = URL.createObjectURL(pdfInstance);
            setUrl(fileURL);
            setSaveLoading(false);
          } catch (error) {
            setSaveLoading(false);

            message.error("Error in saving the post");
          }
        } else {
          setSaveLoading(false);
          message.error("Error in saving the post");
        }
      } else {
        setSaveLoading(false);
        message.error("Error in saving the post");
      }
    };

    useEffect(() => {
      if (url) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${postInformation?.data()?.title}.pdf`;
        link.click();
        URL.revokeObjectURL(url);
        setUrl(null);
      }
    }, [url, postInformation]);

    return (
      <Button
        type="secondary"
        icon={<SaveAlt />}
        onClick={handleClick}
        loading={saveLoading}
      ></Button>
    );
  };
  useEffect(() => {
    getDocs(collection(db, "posts")).then((docSnap) => {
      docSnap.forEach((doc) => {
        if (doc.id === recipe) {
          const postData = doc.data();
          const isPostOwner = postData.id_of_user === currentUser?.uid;
          const isPrivatePost = postData.privatePosts;
          const areCommentsAllowed = postData.allowComments;

          setAllowComments(areCommentsAllowed);

          if (isPrivatePost) {
            if (isPostOwner) {
              setPostInformation(doc);
            } else {
              setPostInformation([]);
              setPrivateLock(true);
            }
          } else {
            setPostInformation(doc);
          }

          setLoading(false);
        }
      });
    });
  }, [recipe, currentUser?.uid]);

  return (
    <>
      {postInformation && typeof postInformation.data === "function" && (
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Recipe",
            name: postInformation.data().title,
            image: postInformation.data().imageUrl,
            description: postInformation.data().desc,
            author: {
              "@type": "Person",
              name: postInformation.data().username_of_user,
            },
            datePublished: "2023-01-01",
            recipeIngredient: postInformation
              .data()
              .ingredients.map((ingredient) => ingredient.label),
            recipeInstructions: postInformation
              .data()
              .instructionsForRecipe.map((step, index) => ({
                "@type": "HowToStep",
                name: `Step ${index + 1}`,
                text: step.text,
              })),
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: postInformation.data().rating || 4.5,
              ratingCount: postInformation.data().ratingCount || 10,
            },
          })}
        </script>
      )}

      {!currentUser ? <Navbar /> : <UserSignedIn />}
      {loading ? (
        <CircularProgress
          color="inherit"
          sx={{ marginLeft: "50%", marginTop: "30%" }}
        />
      ) : postInformation.length === 0 ? null : (
        <div className="single_post_container">
          <div className="single_post_wrapper">
            {/* --------------------------- BreadCrumbs ---------------------- Re-add it later  */
            /* <div className="single_post_breadcrumbs">
                <Link to="/">Home</Link> <Link to={`/${category}`}>{category}</Link>
              </div> */}

            <div className="single_post_header_wrapper">
              <h1 className="post_headerh1">{postInformation.data().title}</h1>
            </div>

            <div className="single_post_recipe_desc">
              <p>{postInformation.data().desc}</p>
            </div>
            <div className="single_post_recipe_poster_information">
              <p>
                Recipe by:{" "}
                <Link
                  to={`/profile/${postInformation.data().username_of_user}`}
                  className="single_post_recipe_linkToProfile"
                >
                  {postInformation.data().username_of_user}{" "}
                </Link>
              </p>
            </div>
            <div className="single_post_recipe_utilities">
              <ShareOnTwitter
                url={window.location}
                text={`Check out ${postInformation?.data()?.title} a ${
                  postInformation?.data()?.category
                } friendly recipe from https://gentlegourmet.app`}
              />
              <ShareOnFacebook
                url={window.location}
                quote={`Check out ${postInformation?.data()?.title} a ${
                  postInformation?.data()?.category
                } friendly recipe from https://gentlegourmet.app`}
              />
              <ShareOnPinterest
                url={window.location}
                description={`Check out ${postInformation?.data()?.title} a ${
                  postInformation?.data()?.category
                } friendly recipe from https://gentlegourmet.app`}
                media={postInformation?.data()?.imageUrl}
              />
            </div>
            <div className="single_post_bookmark">
              {currentUser !== null && (
                <Bookmark
                  postID={postInformation?.id}
                  userID={currentUser?.uid}
                />
              )}
              <SaveButton postInformation={postInformation} />
            </div>
            <img
              src={postInformation.data().imageUrl}
              alt="header"
              width="485"
              height="360"
            />

            <div className="single_post_recipe_ingredients_list">
              <div className="single_post_recipe_containter_ingredients_list">
                <h2>Ingredients</h2>
                {postInformation.data().ingredients.map((ingredient, index) => (
                  <div
                    className="single_post_recipe_ingredient_count"
                    key={index}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox />}
                        label={` ${
                          ingredient?.label[0].toUpperCase() +
                          ingredient?.label.substring(1)
                        } ${
                          ingredient?.calories === null || undefined
                            ? ""
                            : `Calories: ${ingredient?.calories}`
                        }  `}
                      />
                    </FormGroup>
                  </div>
                ))}
              </div>
            </div>
            <div className="single_post_recipe_stepsForRecipe_instructions">
              <div className="single_post_recipe_stepsForRecipe_container_instructions">
                <h2>Directions</h2>
                {postInformation
                  .data()
                  .instructionsForRecipe.map((steps, index) => (
                    <div className="single_post_recipe_steps" key={index}>
                      <CheckCircleOutline /> <p>Step {index + 1}.</p>
                      <p>{steps.text}</p>
                    </div>
                  ))}
              </div>
            </div>

            <RateRecipeDialog
              title={postInformation.data().title}
              GETPOSTSLUG={postInformation.id}
            />
            {!allowComments && (
              <div className="single_post_recipe_comments_allowed">
                <InfoSharp />
                <h3>
                  User has hidden comments for their posts. However, you can
                  rate it.
                </h3>
              </div>
            )}
          </div>
          {/* <div className="single_post_recipe_sidebar_ingredients_list"></div> */}
        </div>
      )}
      {isPrivateLock ? (
        <h1>
          {" "}
          <div className="single_post_container">
            <div className="single_post_wrapper">
              <div className="single_post_header_wrapper">
                <Result
                  status="403"
                  title="This post is private or no longer exists"
                />
              </div>
            </div>
          </div>
        </h1>
      ) : null}
    </>
  );
}
