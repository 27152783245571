import { CircularProgress, Rating, Skeleton } from "@mui/material";
import { Button, message } from "antd";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { db } from "../components/Firebase/firebase";
import "./ShowPostInfiniteScroll.css";
export default function ShowPostInfiniteScroll() {
  const postsRef = collection(db, "posts");
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingLoadNext, setLoadingLoadNext] = useState(false);
  const navigate = useNavigate();
  const [lastDoc, setLastDoc] = useState();
  // Set the Load More button to loading if we are fetching more posts.
  const [queryButtonLoading, setQueryButtonLoading] = useState(false);
  // Create a query to get the first 6 posts.
  const firstQuery = query(
    postsRef,
    orderBy("createdAt", "asc"),
    orderBy("rating", "asc"),
    where("privatePosts", "==", false),
    limit(3)
  );

  function manipulateDescription(postDesc) {
    let a = postDesc.data().desc;

    if (postDesc.data().desc.length > 100) {
      a = postDesc.data().desc.substr(0, 100);
      a += "...";
    }
    return a;
  }
  const updateState = (collections) => {
    const lastDoc = collections.docs[collections.docs.length - 1];
    collections.forEach((doc) => {
      setPosts((newposts) => [...newposts, doc]);
      setLastDoc(lastDoc);
    });
  };
  function loadMorePosts() {
    setQueryButtonLoading(true);
    getDocs(firstQuery).then((docSnaps) => {
      if (docSnaps.docs.length === 0) {
        message.info("No more posts to load.");
        setQueryButtonLoading(false);
        return;
      }
      const next = query(
        postsRef,
        orderBy("createdAt", "asc"),
        orderBy("rating", "asc"),
        where("privatePosts", "==", false),
        limit(3),
        startAfter(lastDoc)
      );
      getDocs(next).then((docSnap) => {
        updateState(docSnap);

        setLoadingLoadNext(true);
        setQueryButtonLoading(false);
      });
    });

    setLoadingLoadNext(false);
  }

  useEffect(() => {
    setLoading(false);
    getDocs(firstQuery).then((docSnap) => {
      const lastVisible = docSnap.docs[docSnap.docs.length - 1];
      // Collect all fetched docs into an array
      const fetchedPosts = [];
      docSnap.forEach((doc) => {
        fetchedPosts.push(doc);
      });
      // Update state with all fetched posts at once
      setPosts(fetchedPosts);
      setLastDoc(lastVisible);
      setLoading(true);
    });
  }, []);

  return (
    <>
      <div className="h-auto w-full overflow-hidden flex flex-row-reverse flex-wrap">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {posts.map((post, index) =>
            loading ? (
              <div
                className="max-w-lg p-12 mx-auto bg-white shadow-lg rounded-lg overflow-hidden"
                key={`infinte_scroll${post.id}`}
              >
                <Link to={`/post/${post.data().category}/${post.id}`}>
                  {post.data().imageURL === null ? (
                    <img
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/gingerrmarket.appspot.com/o/defaultImages%2Fphoto-1546069901-ba9599a7e63c.jpg?alt=media&token=c55c094f-91f0-4d7a-85d4-8ae2d125cce2"
                      }
                      alt="header"
                      className="w-full h-[300px] object-cover object-center rounded-t-lg"
                    />
                  ) : (
                    <img
                      src={post.data().imageUrl}
                      alt="header"
                      className="w-full h-[300px] object-cover object-center rounded-t-lg"
                    />
                  )}
                </Link>

                <div className="my-2">
                  <h2 className="text-xl font-bold mb-2 text-gray-800">
                    {post.data().title}
                  </h2>
                </div>
                <div className="flex flex-row justify-between m-2 md:space-y-2">
                  <div>
                    <p className="text-gray-400">
                      {post.data().calorieTotal} cal
                    </p>
                  </div>
                  <div className="">
                    <Rating
                      name="read-only"
                      value={post.data().rating}
                      readOnly
                      precision={0.5}
                    />
                  </div>
                </div>
                <div className=" pt-4 pb-2">
                  {Array.isArray(post?.data().tags) &&
                    post?.data().tags.map((tag, index) => (
                      <span
                        key={index}
                        onClick={() => {
                          navigate(`/explore/${post.data().category}/${tag}`);
                        }}
                        className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-gray-300 cursor-pointer"
                      >
                        #{tag}
                      </span>
                    ))}
                </div>
                <div className="w-4/5 break-words my-2 py-2">
                  {post.data().desc === undefined
                    ? ""
                    : manipulateDescription(post)}
                </div>
                <div className="flex flex-col">
                  <Link
                    to={`/explore/${post.data().category}/all`}
                    className="inline-block bg-green-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2 hover:bg-green-400 cursor-pointer  md:w-1/3"
                  >
                    <p>{post.data().category}</p>
                  </Link>

                  <Link
                    to={`/profile/${post.data().username_of_user}`}
                    className="text-salmon underline mt-4"
                  >
                    By:{" "}
                    {post.data().username_of_user === undefined
                      ? ""
                      : post.data().username_of_user}
                  </Link>
                </div>
              </div>
            ) : (
              <div>
                <Skeleton variant="rect" width={385} height={300} />
                <Skeleton variant="text" width={100} height={40} />
                <Skeleton variant="circle" width={40} height={40} />
                <Skeleton variant="rect" width={100} height={20} />
                <Skeleton variant="text" width={300} height={20} />
                <Skeleton variant="text" width={100} height={20} />
              </div>
            )
          )}
        </div>
      </div>
      <div className="mb-4 p-4 w-full flex justify-center items-center">
        <Button
          color="primary"
          loading={queryButtonLoading}
          onClick={() => loadMorePosts()}
        >
          Load More..
        </Button>
      </div>
    </>
  );
}
